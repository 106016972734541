import React from "react";
import { connect } from "react-redux";
import {
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  Typography,
  IconButton,
  Button,
  Link
} from "@material-ui/core";
import { Menu as MenuIcon, AccountCircle } from "@material-ui/icons";
import { withRouter } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { logout } from "../../redux/actions";

class Header extends React.Component {
  state = {
    anchorEl: null
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleLogout = () => {
    const { logout, history } = this.props;
    this.handleClose();
    logout();
    history.push("/login");
  };

  render() {
    const { isAuthenticated, user, isLoggingOut } = this.props;
    const { anchorEl } = this.state;
    const isOpen = Boolean(anchorEl);

    return (
      <AppBar position="sticky">
        <Toolbar>
          <Link
            component={RouterLink}
            to="/"
            style={{ flexGrow: 1 }}
            color="inherit"
          >
            <Typography variant="h6" color="inherit" noWrap>
              Klimaplan
            </Typography>
          </Link>
          {!isAuthenticated && (
            <>
              <Button component={RouterLink} color="inherit" to="/login">
                Log ind
              </Button>
              <Button component={RouterLink} color="inherit" to="/sign-up">
                Opret bruger
              </Button>
            </>
          )}
          {isAuthenticated && (
            <>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={this.handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                open={isOpen}
                keepMounted
                onClose={this.handleClose}
              >
                <MenuItem
                  onClick={this.handleClose}
                  component={RouterLink}
                  to="/settings"
                >
                  Indstillinger
                </MenuItem>
                <MenuItem
                  onClick={this.handleLogout}
                  disabled={isLoggingOut}
                  title={`Log ud fra ${user.email}`}
                >
                  {isLoggingOut ? "Logger ud..." : "Log ud"}
                </MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>
    );
  }
}

const mapStateToProps = state => {
  const { isAuthenticated, user, isLoggingOut } = state.users;
  return { isAuthenticated, user, isLoggingOut };
};

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
