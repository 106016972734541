import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { TextField } from "@material-ui/core";
import { onToggleTaskType } from "../../redux/actions";

class TaskToggleType extends React.Component {
  handleToggleTaskType = event => {
    this.props.onToggleTaskType(event.target.value);
  };

  render() {
    const { isSocialMediaEnabled } = this.props;

    return (
      <TextField
        select
        fullWidth
        margin="normal"
        variant="outlined"
        label="Opgavetype"
        value={isSocialMediaEnabled}
        onChange={this.handleToggleTaskType}
        SelectProps={{
          native: true
        }}
      >
        <option value={true}>Social Media</option>
        <option value={false}>Email</option>
      </TextField>
    );
  }
}

const mapStateToProps = state => {
  const { isSocialMediaEnabled } = state.tasks;
  return { isSocialMediaEnabled };
};

const mapDispatchToProps = dispatch => ({
  onToggleTaskType: () => dispatch(onToggleTaskType())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TaskToggleType)
);
