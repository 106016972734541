import React from "react";
import { connect } from "react-redux";
import { TextField } from "@material-ui/core";
import { onChangeCity } from "../../redux/actions";
import cities from "../../data/cities.json";

class TaskToggleType extends React.Component {
  handleChangeCity = event => {
    this.props.onChangeCity(event.target.value);
  };

  render() {
    const { city } = this.props;

    return (
      <TextField
        select
        fullWidth
        margin="normal"
        variant="outlined"
        label="By"
        value={city}
        onChange={this.handleChangeCity}
        SelectProps={{
          native: true
        }}
      >
        {cities.map((city, i) => (
          <option key={i} value={city}>
            {city}
          </option>
        ))}
      </TextField>
    );
  }
}

const mapStateToProps = state => {
  const { city } = state.users;
  return { city };
};

const mapDispatchToProps = dispatch => ({
  onChangeCity: city => dispatch(onChangeCity(city))
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskToggleType);
