import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { TextField, Button, Paper } from "@material-ui/core";
import {
  fetchTaskEmail,
  completeTaskEmail,
  onChangeMailSubject,
  onChangeMailContent
} from "../../redux/actions";

class TaskMail extends React.Component {
  handleChangeMailSubject = event => {
    this.props.onChangeMailSubject(event.target.value);
  };

  handleChangeMailContent = event => {
    this.props.onChangeMailContent(event.target.value);
  };

  handleFetchTaskEmail = event => {
    event.preventDefault();
    const { fetchTaskEmail, user, city } = this.props;

    if (!user) {
      return alert("No user");
    }

    fetchTaskEmail(user, city);
  };

  handleCompleteTaskEmail = async event => {
    event.preventDefault();
    const {
      completeTaskEmail,
      fetchTaskEmail,
      politician,
      user,
      city
    } = this.props;

    if (!user) {
      console.log("No user");
      return;
    }

    if (!politician) {
      console.log("No politician");
      return;
    }

    const completed = await completeTaskEmail(user.uid, politician.email);

    if (completed) {
      fetchTaskEmail(user, city);
    }
  };

  render() {
    const {
      isFetchingEvents,
      politician,
      isFetchingTaskEmail,
      isCompletingTaskEmail,
      mailSubject,
      mailContent
    } = this.props;

    if (isFetchingEvents) {
      return <p>Henter...</p>;
    }

    const isFormDisabled = isFetchingTaskEmail || isCompletingTaskEmail;
    const isCompleteTaskDisabled =
      isFetchingTaskEmail ||
      isCompletingTaskEmail ||
      !politician ||
      !mailContent;

    const mailLink = `mailto:${
      politician ? politician.email : ""
    }?subject=${encodeURIComponent(mailSubject)}&body=${encodeURIComponent(
      mailContent
    )}`;

    return (
      <Paper className="paper">
        <TextField
          label="Email"
          variant="outlined"
          margin="normal"
          type="email"
          value={politician ? politician.email : ""}
          fullWidth
          disabled
        />
        <TextField
          label="Emne"
          variant="outlined"
          margin="normal"
          type="text"
          value={mailSubject}
          onChange={this.handleChangeMailSubject}
          disabled={isFormDisabled}
          autoComplete="subject"
          fullWidth
        />
        <TextField
          label="Tekst"
          variant="outlined"
          margin="normal"
          type="text"
          value={mailContent}
          onChange={this.handleChangeMailContent}
          disabled={isFormDisabled}
          autoComplete="text"
          fullWidth
          multiline
          rows="8"
        />
        <form method="POST" onSubmit={this.handleFetchTaskEmail}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isFetchingTaskEmail}
            fullWidth
            style={{ marginTop: 10 }}
          >
            {isFetchingTaskEmail ? "Henter opgave..." : "Hent opgave"}
          </Button>
        </form>
        <form method="POST" onSubmit={this.handleCompleteTaskEmail}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isCompleteTaskDisabled}
            fullWidth
            style={{ marginTop: 10 }}
          >
            {isCompletingTaskEmail ? "Afslutter..." : "Afslut opgave"}
          </Button>
        </form>
        {/* <Button
          variant="contained"
          color="secondary"
          href={mailLink}
          fullWidth
          style={{ marginTop: 10 }}
        >
          Åbn i email-klient
        </Button> */}
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  const { user, city } = state.users;
  const {
    isFetchingTaskEmail,
    isCompletingTaskEmail,
    politician,
    mailSubject,
    mailContent
  } = state.tasks;

  return {
    user,
    isFetchingTaskEmail,
    isCompletingTaskEmail,
    politician,
    mailSubject,
    mailContent,
    city
  };
};

const mapDispatchToProps = dispatch => ({
  fetchTaskEmail: (emailUser, city) =>
    dispatch(fetchTaskEmail(emailUser, city)),
  completeTaskEmail: (emailUser, emailPolitician) =>
    dispatch(completeTaskEmail(emailUser, emailPolitician)),
  onChangeMailSubject: mailSubject =>
    dispatch(onChangeMailSubject(mailSubject)),
  onChangeMailContent: mailContent => dispatch(onChangeMailContent(mailContent))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TaskMail)
);
