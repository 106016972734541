import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyD77BmHJh_rgX9AhdBnit_wK8krF16Psqg",
  authDomain: "boxwood-academy-251913.firebaseapp.com",
  databaseURL: "https://boxwood-academy-251913.firebaseio.com",
  projectId: "boxwood-academy-251913",
  storageBucket: "boxwood-academy-251913.appspot.com",
  messagingSenderId: "312344366969",
  appId: "1:312344366969:web:07ac748324474ed83129a8"
};

export const myFirebase = firebase.initializeApp(firebaseConfig);
const baseDb = myFirebase.firestore();
export const db = baseDb;
