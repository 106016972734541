import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { TextField, Paper, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { onChangeEmail, forgotPassword } from "../../redux/actions";

class ForgotPassword extends React.Component {
  handleChangeEmail = event => {
    this.props.onChangeEmail(event.target.value);
  };

  handleForgotPassword = event => {
    event.preventDefault();
    const { email, forgotPassword } = this.props;
    forgotPassword(email);
  };

  render() {
    const { isAuthenticated, email } = this.props;

    return (
      <>
        {isAuthenticated && (
          <Alert severity="warning" style={{ marginBottom: 10 }}>
            Allerede logget ind.
          </Alert>
        )}
        <Paper className="paper">
          <form onSubmit={this.handleForgotPassword} noValidate>
            <TextField
              label="Email"
              variant="outlined"
              margin="normal"
              type="email"
              value={email}
              onChange={this.handleChangeEmail}
              disabled={isAuthenticated}
              autoComplete="email"
              autoFocus
              fullWidth
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled
              style={{ marginTop: 10 }}
            >
              Send forespørgsel
            </Button>
            <Button
              component={Link}
              to="/login"
              variant="contained"
              color="secondary"
              fullWidth
              style={{ marginTop: 10 }}
            >
              Annullér
            </Button>
          </form>
        </Paper>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { email, isAuthenticated } = state.users;
  return { email, isAuthenticated };
};

const mapDispatchToProps = dispatch => ({
  onChangeEmail: email => dispatch(onChangeEmail(email)),
  forgotPassword: email => dispatch(forgotPassword(email))
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
