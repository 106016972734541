import update from "immutability-helper";

const initialState = {
  isFetchingTaskEmail: false,
  isFetchingTaskSocialMedia: false,

  isCompletingTaskEmail: false,
  isCompletingTaskSocialMedia: false,

  politician: null,
  url: "",

  mailSubject: "",
  mailContent: "",
  socialMediaContent: "",

  error: "",

  isFetchingEvents: false,

  events: [],

  isSocialMediaEnabled: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "ON_TOGGLE_TASK_TYPE": {
      return update(state, {
        isSocialMediaEnabled: { $apply: bool => !bool }
      });
    }

    case "ON_CHANGE_MAIL_SUBJECT": {
      return update(state, {
        mailSubject: { $set: action.payload.mailSubject }
      });
    }

    case "ON_CHANGE_MAIL_CONTENT": {
      return update(state, {
        mailContent: { $set: action.payload.mailContent }
      });
    }

    case "ON_CHANGE_SOCIAL_MEDIA_CONTENT": {
      return update(state, {
        socialMediaContent: { $set: action.payload.socialMediaContent }
      });
    }

    case "FETCH_TASK_EMAIL_REQUEST": {
      return update(state, {
        isFetchingTaskEmail: { $set: true },
        error: { $set: "" }
      });
    }

    case "FETCH_TASK_EMAIL_SUCCESS": {
      return update(state, {
        isFetchingTaskEmail: { $set: false },
        politician: { $set: action.politician },
        mailSubject: { $set: action.mailSubject },
        mailContent: { $set: action.mailContent },
        error: { $set: "" }
      });
    }

    case "FETCH_TASK_EMAIL_ERROR": {
      return update(state, {
        isFetchingTaskEmail: { $set: false },
        error: { $set: action.error }
      });
    }

    case "FETCH_TASK_SOCIAL_MEDIA_REQUEST": {
      return update(state, {
        isFetchingTaskSocialMedia: { $set: true },
        error: { $set: "" }
      });
    }

    case "FETCH_TASK_SOCIAL_MEDIA_SUCCESS": {
      return update(state, {
        isFetchingTaskSocialMedia: { $set: false },
        url: { $set: action.url },
        socialMediaContent: { $set: action.socialMediaContent },
        error: { $set: "" }
      });
    }

    case "FETCH_TASK_SOCIAL_MEDIA_ERROR": {
      return update(state, {
        isFetchingTaskSocialMedia: { $set: false },
        error: { $set: action.error }
      });
    }

    case "COMPLETE_TASK_EMAIL_REQUEST": {
      return update(state, {
        isCompletingTaskEmail: { $set: true },
        error: { $set: "" }
      });
    }

    case "COMPLETE_TASK_EMAIL_SUCCESS": {
      return update(state, {
        isCompletingTaskEmail: { $set: false },
        events: { $push: [action.task] },
        error: { $set: "" }
      });
    }

    case "COMPLETE_TASK_EMAIL_ERROR": {
      return update(state, {
        isCompletingTaskEmail: { $set: false },
        error: { $set: action.error }
      });
    }

    case "COMPLETE_TASK_SOCIAL_MEDIA_REQUEST": {
      return update(state, {
        isCompletingTaskSocialMedia: { $set: true },
        error: { $set: "" }
      });
    }

    case "COMPLETE_TASK_SOCIAL_MEDIA_SUCCESS": {
      return update(state, {
        isCompletingTaskSocialMedia: { $set: false },
        events: { $push: [action.task] },
        error: { $set: "" }
      });
    }

    case "COMPLETE_TASK_SOCIAL_MEDIA_ERROR": {
      return update(state, {
        isCompletingTaskSocialMedia: { $set: false },
        error: { $set: action.error }
      });
    }

    case "FETCH_EVENTS_REQUEST": {
      return update(state, {
        isFetchingEvents: { $set: true }
      });
    }

    case "FETCH_EVENTS_SUCCESS": {
      return update(state, {
        isFetchingEvents: { $set: false },
        events: { $set: action.events }
      });
    }

    case "FETCH_EVENTS_ERROR": {
      return update(state, {
        isFetchingEvents: { $set: false }
      });
    }

    default:
      return state;
  }
}
