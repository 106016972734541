export const pad = (num, size) => ("000000000" + num).substr(-size);

export const dateToYYYYMMDD = d => {
  const year = d.getFullYear();
  const month = pad(d.getMonth() + 1, 2);
  const date = pad(d.getDate(), 2);
  return `${year}-${month}-${date}`;
};

export const weighArray = (array, weight, matchVariable, matchValue) =>
  array.reduce((accumulator, element) => {
    const match = element.fields[matchVariable];

    return accumulator.concat(
      !!match && match.trim() === matchValue
        ? Array(weight).fill(element)
        : [element]
    );
  }, []);

export const getRandom = arr => arr[Math.floor(Math.random() * arr.length)];

export const capitalize = s => s[0].toUpperCase() + s.substr(1);
