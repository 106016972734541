import React from "react";
import { connect } from "react-redux";
import { Grid, Paper } from "@material-ui/core";
import { fetchEvents } from "../../redux/actions";
import TaskMail from "./TaskMail";
import TaskStatistics from "./TaskStatistics";
import TaskSocialMedia from "./TaskSocialMedia";
import TaskToggleType from "./TaskToggleType";
import TaskSelectCity from "./TaskSelectCity";

class TaskList extends React.Component {
  componentDidMount() {
    this.handleFetchEvents();
  }

  handleFetchEvents = () => {
    this.props.fetchEvents();
  };

  render() {
    const { events, isFetchingEvents, user, isSocialMediaEnabled } = this.props;

    if (isFetchingEvents) {
      return <p>Henter...</p>;
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TaskStatistics events={events} user={user} />
        </Grid>
        <Grid item xs={12}>
          <Paper className="paper">
            <TaskToggleType />
            {/* <TaskSelectCity /> */}
          </Paper>
        </Grid>
        {!isSocialMediaEnabled && (
          <Grid item xs={12}>
            <TaskMail />
          </Grid>
        )}
        {isSocialMediaEnabled && (
          <Grid item xs={12}>
            <TaskSocialMedia />
          </Grid>
        )}
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state.users;
  const { isFetchingEvents, events, isSocialMediaEnabled } = state.tasks;
  return { user, isFetchingEvents, events, isSocialMediaEnabled };
};

const mapDispatchToProps = dispatch => ({
  fetchEvents: () => dispatch(fetchEvents())
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskList);
