import React from "react";
import { Paper, Grid } from "@material-ui/core";
import { dateToYYYYMMDD } from "../../utils";

class TaskStatistics extends React.Component {
  render() {
    const { events, user } = this.props;

    const eventsToday = events.filter(
      event => event.date === dateToYYYYMMDD(new Date())
    );
    const eventsTodayByUser =
      !!user && !!user.uid
        ? events.filter(
            event =>
              event.date === dateToYYYYMMDD(new Date()) &&
              event.userId === user.uid
          )
        : [];
    const eventsByUser =
      !!user && !!user.uid
        ? events.filter(event => event.userId === user.uid)
        : [];

    return (
      <Grid container justify="center" spacing={2} style={{ flexGrow: 1 }}>
        <Grid item xs={3}>
          <Paper className="paper" align="center">
            <p className="text-muted m-0 text-uppercase">I alt fra alle</p>
            <h1 className="display-3 m-0 font-weight-bold">{events.length}</h1>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className="paper" align="center">
            <p className="text-muted m-0 text-uppercase">I dag fra alle</p>
            <h1 className="display-3 m-0 font-weight-bold">
              {eventsToday.length}
            </h1>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className="paper" align="center">
            <p className="text-muted m-0 text-uppercase">Fra dig i alt</p>
            <h1 className="display-3 m-0 font-weight-bold">
              {eventsByUser.length}
            </h1>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className="paper" align="center">
            <p className="text-muted m-0 text-uppercase">Fra dig i dag</p>
            <h1 className="display-3 m-0 font-weight-bold">
              {eventsTodayByUser.length}
            </h1>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default TaskStatistics;
