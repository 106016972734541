import Mustache from "mustache";
import { subjects, sections, variables } from "../../data/mailContent.json";
import { getRandom, dateToYYYYMMDD, weighArray, capitalize } from "../../utils";
import { db } from "../../firebase/firebase";

const AIRTABLE_BASE_URL = "https://api.airtable.com/v0";
const AIRTABLE_API_KEY = "keyIybEhppJ1uiOr8";
const AIRTABLE_BASE_POLITICIANS = "appgpj9JqXgSAcGXW/Politikere";
const AIRTABLE_BASE_SOCIAL_MEDIA_TEXT = "app6s5FU5bUQixdj6/SoMe";
const AIRTABLE_BASE_SOCIAL_MEDIA_POSTS = "apperZktyR4z8IZXZ/output%20facebook";

// const AIRTABLE_BASE_USERS = "appLAxif9vNMFLILG/Users";

const getMailSubject = () => {
  const mailSubject = getRandom(subjects);
  const solution = getRandom(variables.SOLUTION);
  const climate_crisis = getRandom(variables.CLIMATE_CRISIS);
  return Mustache.render(mailSubject, { solution, climate_crisis });
};

const getMailContent = (name, politicianName, city) => {
  const mailContent = sections
    .map(section => capitalize(getRandom(section)))
    .join("\n\n"); // TODO: fix capitalize
  const solution = getRandom(variables.SOLUTION);
  const climate_crisis = getRandom(variables.CLIMATE_CRISIS);

  return Mustache.render(mailContent, {
    name,
    politician: politicianName,
    city,
    solution,
    climate_crisis
  });
};

const getSocialMediaContent = async () => {
  const url = `${AIRTABLE_BASE_URL}/${AIRTABLE_BASE_SOCIAL_MEDIA_TEXT}?api_key=${AIRTABLE_API_KEY}`;
  const response = await fetch(url);
  const json = await response.json();
  const socialMediaContent = json.records
    .sort(
      (a, b) =>
        Object.values(a.fields).slice(-1) - Object.values(b.fields).slice(-1)
    )
    .map(record => {
      const options = Object.values(record.fields).slice(0, -1);
      const selection = getRandom(options);
      return selection;
    })
    .join(" ");
  return socialMediaContent;
};

const getSocialMediaURL = async () => {
  const url = `${AIRTABLE_BASE_URL}/${AIRTABLE_BASE_SOCIAL_MEDIA_POSTS}?api_key=${AIRTABLE_API_KEY}`;
  const response = await fetch(url);
  const json = await response.json();
  const post = getRandom(json.records);
  return post.fields.Notes;
};

export const fetchEvents = () => async dispatch => {
  dispatch({ type: "FETCH_EVENTS_REQUEST" });

  try {
    // Retrieve past events from Airtable
    const snapshot = await db.collection("events").get();

    //
    const events = snapshot.docs.map(event => {
      const { userId, emailPolitician, date } = event.data();
      return { userId, emailPolitician, date };
    });

    //
    dispatch({ type: "FETCH_EVENTS_SUCCESS", events });
  } catch (error) {
    console.log(error);
    dispatch({ type: "FETCH_EVENTS_ERROR", error });
  }
};

export const onToggleTaskType = () => ({
  type: "ON_TOGGLE_TASK_TYPE"
});

export const onChangeMailSubject = mailSubject => ({
  type: "ON_CHANGE_MAIL_SUBJECT",
  payload: { mailSubject }
});

export const onChangeMailContent = mailContent => ({
  type: "ON_CHANGE_MAIL_CONTENT",
  payload: { mailContent }
});

export const onChangeSocialMediaContent = socialMediaContent => ({
  type: "ON_CHANGE_SOCIAL_MEDIA_CONTENT",
  payload: { socialMediaContent }
});

const getPoliticianEmailsExcluded = async userIdLoggedIn => {
  // Retrieve past events
  const snapshot = await db.collection("events").get();

  // Generate an array of politicians who should not receive an email
  return snapshot.docs
    .filter(event => {
      const { userId, emailPolitician, date } = event.data();

      const today = dateToYYYYMMDD(new Date());
      const isEmailPoliticianValid = !!emailPolitician;
      const hasReceivedToday = !!date && date === today;
      const hasReceivedByUser = !!userId && userId === userIdLoggedIn;

      return isEmailPoliticianValid && (hasReceivedToday || hasReceivedByUser);
    })
    .map(event => event.data().emailPolitician);
};

const getPoliticians = async () => {
  const url = `${AIRTABLE_BASE_URL}/${AIRTABLE_BASE_POLITICIANS}?api_key=${AIRTABLE_API_KEY}`;
  const response = await fetch(url);
  const json = await response.json();
  return json.records;
};

const getPolitician = async (userIdLoggedIn, city) => {
  const politicians = await getPoliticians();
  const emailsExcluded = await getPoliticianEmailsExcluded(userIdLoggedIn);

  // Exclude politicians who have already received an email today or received an email from this user in the past
  const politiciansAllowed = politicians.filter(
    politician =>
      !!politician.fields.Email &&
      !emailsExcluded.includes(politician.fields.Email)
    //  && politician.fields.Valgkreds === city
  );

  // Increase array to likelihood of certain politicians is higher
  const politiciansWeighted = weighArray(
    politiciansAllowed,
    3,
    "Prioritet",
    "Høj"
  );

  // Randomly select a politician from the pool of politicians to whom we want to send an email
  const politician = getRandom(politiciansWeighted);

  // Return formatted
  return {
    name: politician.fields.Navn,
    email: politician.fields.Email
  };
};

export const fetchTaskEmail = (user, city) => async dispatch => {
  dispatch({ type: "FETCH_TASK_EMAIL_REQUEST" });

  try {
    //
    const politician = await getPolitician(user.uid, city);

    // Concatenate sections
    const mailSubject = getMailSubject();

    // Concatenate sections
    const mailContent = getMailContent(
      user.displayName,
      politician.name,
      "København"
    );

    //
    dispatch({
      type: "FETCH_TASK_EMAIL_SUCCESS",
      politician,
      mailSubject,
      mailContent
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: "FETCH_TASK_EMAIL_ERROR", error });
  }
};

export const fetchTaskSocialMedia = user => async dispatch => {
  dispatch({ type: "FETCH_TASK_SOCIAL_MEDIA_REQUEST" });

  try {
    // Retrieve past events
    const snapshot = await db.collection("events").get();

    // Select a random
    const url = await getSocialMediaURL();

    // Concatenate sections
    const socialMediaContent = await getSocialMediaContent(
      user.displayName,
      "recipient name",
      "København"
    );

    //
    dispatch({
      type: "FETCH_TASK_SOCIAL_MEDIA_SUCCESS",
      url,
      socialMediaContent
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: "FETCH_TASK_SOCIAL_MEDIA_ERROR", error });
  }
};

export const completeTaskEmail = (
  userId,
  emailPolitician
) => async dispatch => {
  dispatch({ type: "COMPLETE_TASK_EMAIL_REQUEST" });

  try {
    const task = {
      userId,
      emailPolitician,
      date: dateToYYYYMMDD(new Date())
    };
    await db.collection("events").add(task);
    dispatch({ type: "COMPLETE_TASK_EMAIL_SUCCESS", task });
    return true;
  } catch (error) {
    console.log(error);
    dispatch({
      type: "COMPLETE_TASK_EMAIL_ERROR",
      error
    });
    return false;
  }
};

export const completeTaskSocialMedia = (
  userId,
  emailPolitician
) => async dispatch => {
  dispatch({ type: "COMPLETE_TASK_SOCIAL_MEDIA_REQUEST" });

  try {
    const task = {
      userId,
      emailPolitician,
      date: dateToYYYYMMDD(new Date())
    };
    await db.collection("events").add(task);
    dispatch({ type: "COMPLETE_TASK_SOCIAL_MEDIA_SUCCESS", task });
    return true;
  } catch (error) {
    console.log(error);
    dispatch({
      type: "COMPLETE_TASK_SOCIAL_MEDIA_ERROR",
      error
    });
    return false;
  }
};
