import update from "immutability-helper";

const initialState = {
  // Inputs
  name: "Steven",
  location: "København",
  email: "steven.gandso@gmail.com",
  password: "steven",
  isNotificationsAllowed: true,

  //
  isLoggingIn: false,
  isAuthenticated: false,
  isSigningUp: false,
  isLoggingOut: false,
  isVerifying: false,
  isUpdatingUser: false,

  //
  loginError: "",
  signUpError: "",
  logoutError: "",
  updateUserError: "",

  //
  user: {},
  city: "Københavns Kommune"
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "ON_CHANGE_CITY": {
      const { city } = action.payload;
      return update(state, { city: { $set: city } });
    }

    case "ON_CHANGE_NAME": {
      const { name } = action.payload;
      return update(state, { name: { $set: name } });
    }

    case "ON_CHANGE_LOCATION": {
      const { location } = action.payload;
      return update(state, { location: { $set: location } });
    }

    case "ON_CHANGE_EMAIL": {
      const { email } = action.payload;
      return update(state, { email: { $set: email } });
    }

    case "ON_CHANGE_PASSWORD": {
      const { password } = action.payload;
      return update(state, { password: { $set: password } });
    }

    case "ON_CHANGE_IS_NOTIFICATIONS_ALLOWED": {
      const { isNotificationsAllowed } = action.payload;
      console.log(isNotificationsAllowed);
      return update(state, {
        isNotificationsAllowed: { $set: isNotificationsAllowed }
      });
    }

    case "SIGN_UP_REQUEST": {
      return update(state, {
        isSigningUp: { $set: true },
        signUpError: { $set: "" }
      });
    }

    case "SIGN_UP_SUCCESS": {
      return update(state, {
        isSigningUp: { $set: false },
        isAuthenticated: { $set: true },
        user: { $set: action.user },
        signUpError: { $set: "" }
      });
    }

    case "SIGN_UP_ERROR": {
      return update(state, {
        isSigningUp: { $set: false },
        signUpError: { $set: action.error }
      });
    }

    case "LOGIN_REQUEST": {
      return update(state, {
        isLoggingIn: { $set: true },
        loginError: { $set: "" }
      });
    }

    case "LOGIN_SUCCESS": {
      return update(state, {
        isLoggingIn: { $set: false },
        isAuthenticated: { $set: true },
        user: { $set: action.user },
        loginError: { $set: "" }
      });
    }

    case "LOGIN_ERROR": {
      return update(state, {
        isLoggingIn: { $set: false },
        isAuthenticated: { $set: false },
        user: { $set: {} },
        loginError: { $set: action.error }
      });
    }

    case "LOGOUT_REQUEST": {
      return update(state, {
        isLoggingOut: { $set: true },
        logoutError: { $set: "" }
      });
    }

    case "LOGOUT_SUCCESS": {
      return update(state, {
        isLoggingOut: { $set: false },
        isAuthenticated: { $set: false },
        user: { $set: {} }
      });
    }

    case "LOGOUT_ERROR": {
      return update(state, {
        isLoggingOut: { $set: false },
        logoutError: { $set: "Error" }
      });
    }

    case "VERIFY_REQUEST":
      return update(state, {
        isVerifying: { $set: true },
        verifyingError: { $set: "" }
      });

    case "VERIFY_SUCCESS":
      return update(state, {
        isVerifying: { $set: false }
      });

    case "UPDATE_USER_REQUEST": {
      return update(state, {
        isUpdatingUser: { $set: true },
        updateUserError: { $set: "" }
      });
    }

    case "UPDATE_USER_SUCCESS": {
      return update(state, {
        isUpdatingUser: { $set: false },
        updateUserError: { $set: "" }
      });
    }

    case "UPDATE_USER_ERROR": {
      return update(state, {
        isUpdatingUser: { $set: false },
        updateUserError: { $set: "Error" }
      });
    }

    default:
      return state;
  }
}
