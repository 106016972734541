import React from "react";
import { connect } from "react-redux";
import { Link as RouterLink, Redirect } from "react-router-dom";
import {
  TextField,
  InputAdornment,
  IconButton,
  Grid,
  Button,
  Paper,
  Link
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import {
  login,
  logout,
  onChangeEmail,
  onChangePassword
} from "../../redux/actions";

class Login extends React.Component {
  state = {
    showPassword: false
  };

  handleChangeEmail = event => {
    this.props.onChangeEmail(event.target.value);
  };

  handleChangePassword = event => {
    this.props.onChangePassword(event.target.value);
  };

  handleLogin = event => {
    event.preventDefault();
    const { email, password, login, history } = this.props;
    login(email, password);
  };

  handleLogout = event => {
    event.preventDefault();
    this.props.logout();
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  render() {
    const {
      isAuthenticated,
      email,
      password,
      isLoggingIn,
      loginError,
      logoutError
    } = this.props;
    const isFormDisabled = isLoggingIn || isAuthenticated;

    if (isAuthenticated) {
      return <Redirect to="/" />;
    }

    return (
      <>
        {logoutError && (
          <Alert severity="error" style={{ marginBottom: 10 }}>
            {logoutError}
          </Alert>
        )}
        {loginError && <Alert severity="error">{loginError}</Alert>}
        <Paper className="paper">
          <form onSubmit={this.handleLogin} noValidate>
            <TextField
              label="Email"
              variant="outlined"
              margin="normal"
              type="email"
              value={email}
              onChange={this.handleChangeEmail}
              disabled={isFormDisabled}
              autoComplete="email"
              autoFocus
              fullWidth
            />
            <TextField
              type={this.state.showPassword ? "text" : "password"}
              value={password}
              onChange={this.handleChangePassword}
              label="Kodeord"
              variant="outlined"
              margin="normal"
              disabled={isFormDisabled}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={this.handleClickShowPassword}
                      edge="end"
                    >
                      {this.state.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isFormDisabled}
              style={{ marginTop: 10 }}
            >
              {isLoggingIn ? "Logger ind..." : "Log ind"}
            </Button>
            <Grid container style={{ marginTop: 10 }}>
              <Grid item xs>
                <Link component={RouterLink} to="/forgot-password">
                  Glemt kodeord?
                </Link>
              </Grid>
              <Grid item>
                <Link component={RouterLink} to="/sign-up">
                  Har du ingen bruger?
                </Link>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    email,
    password,
    isAuthenticated,
    isLoggingIn,
    isLoggingOut,
    loginError,
    logoutError,
    user
  } = state.users;
  return {
    email,
    password,
    isAuthenticated,
    isLoggingIn,
    isLoggingOut,
    loginError,
    logoutError,
    user
  };
};

const mapDispatchToProps = dispatch => ({
  onChangeEmail: email => dispatch(onChangeEmail(email)),
  onChangePassword: password => dispatch(onChangePassword(password)),
  login: (email, password) => dispatch(login(email, password)),
  logout: () => dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
