import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { TextField, Paper, Button } from "@material-ui/core";
import {
  fetchTaskSocialMedia,
  completeTaskSocialMedia,
  onChangeSocialMediaContent
} from "../../redux/actions";

class TaskSocialMedia extends React.Component {
  handleChangeSocialMediaContent = event => {
    this.props.onChangeSocialMediaContent(event.target.value);
  };

  handleFetchTaskSocialMedia = event => {
    event.preventDefault();
    const { fetchTaskSocialMedia, user } = this.props;

    if (!user) {
      return alert("No user");
    }

    fetchTaskSocialMedia(user);
  };

  handleCompleteTaskSocialMedia = async event => {
    event.preventDefault();
    const {
      completeTaskSocialMedia,
      fetchTaskSocialMedia,
      url,
      user
    } = this.props;

    if (!user) {
      console.log("No user");
      return;
    }

    if (!url) {
      console.log("No URL");
      return;
    }

    const completed = await completeTaskSocialMedia(user.uid, url);

    if (completed) {
      fetchTaskSocialMedia(user);
    }
  };

  render() {
    const {
      url,
      isFetchingTaskSocialMedia,
      isCompletingTaskSocialMedia,
      socialMediaContent
    } = this.props;

    const isFormDisabled =
      isFetchingTaskSocialMedia || isCompletingTaskSocialMedia;
    const isCompleteTaskDisabled =
      isFetchingTaskSocialMedia ||
      isCompletingTaskSocialMedia ||
      !url ||
      !socialMediaContent;

    return (
      <Paper className="paper">
        <TextField
          label="URL"
          variant="outlined"
          margin="normal"
          type="text"
          value={url}
          disabled
          fullWidth
        />
        <TextField
          label="Tekst"
          variant="outlined"
          margin="normal"
          type="text"
          value={socialMediaContent}
          onChange={this.handleChangeSocialMediaContent}
          disabled={isFormDisabled}
          autoComplete="text"
          fullWidth
          multiline
          rows="8"
        />
        {url && (
          <iframe
            src={`https://www.facebook.com/plugins/post.php?href=${encodeURI(
              url
            )}&width=500`}
            style={{
              marginTop: 10,
              width: 500,
              height: 700,
              border: "none",
              overflow: "hidden"
            }}
            scrolling="no"
            frameBorder="0"
            allowtransparency="true"
            allow="encrypted-media"
            title="Facebook Post"
          ></iframe>
        )}
        <form method="POST" onSubmit={this.handleFetchTaskSocialMedia}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isFetchingTaskSocialMedia}
            fullWidth
            style={{ marginTop: 10 }}
          >
            {isFetchingTaskSocialMedia ? "Henter opgave..." : "Hent opgave"}
          </Button>
        </form>
        <form method="POST" onSubmit={this.handleCompleteTaskSocialMedia}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isCompleteTaskDisabled}
            fullWidth
            style={{ marginTop: 10 }}
          >
            {isCompletingTaskSocialMedia ? "Afslutter..." : "Afslut opgave"}
          </Button>
        </form>
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state.users;
  const {
    isFetchingTaskSocialMedia,
    isCompletingTaskSocialMedia,
    url,
    socialMediaContent
  } = state.tasks;

  return {
    user,
    isFetchingTaskSocialMedia,
    isCompletingTaskSocialMedia,
    url,
    socialMediaContent
  };
};

const mapDispatchToProps = dispatch => ({
  fetchTaskSocialMedia: emailUser => dispatch(fetchTaskSocialMedia(emailUser)),
  completeTaskSocialMedia: (emailUser, url) =>
    dispatch(completeTaskSocialMedia(emailUser, url)),
  onChangeSocialMediaContent: socialMediaContent =>
    dispatch(onChangeSocialMediaContent(socialMediaContent))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TaskSocialMedia)
);
