import React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import {
  TextField,
  Paper,
  Button,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import {
  signUp,
  onChangeName,
  onChangeEmail,
  onChangePassword,
  onChangeLocation,
  onChangeIsNotificationsAllowed
} from "../../redux/actions";

class SignUp extends React.Component {
  state = {
    showPassword: false
  };

  handleChangeName = event => {
    this.props.onChangeName(event.target.value);
  };

  handleChangeEmail = event => {
    this.props.onChangeEmail(event.target.value);
  };

  handleChangePassword = event => {
    this.props.onChangePassword(event.target.value);
  };

  handleChangeLocation = event => {
    this.props.onChangeLocation(event.target.value);
  };

  handleChangeIsNotificationsAllowed = event => {
    this.props.onChangeIsNotificationsAllowed(event.target.value);
  };

  handleSignUp = event => {
    event.preventDefault();
    const { email, password, name, userLocation, signUp } = this.props;
    signUp(email, password, name, userLocation);
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  render() {
    const {
      isAuthenticated,
      name,
      email,
      password,
      userLocation,
      isNotificationsAllowed,
      isSigningUp,
      signUpError
    } = this.props;
    const isFormDisabled = isSigningUp || isAuthenticated;

    if (isAuthenticated) {
      return <Redirect to="/" />;
    }

    return (
      <>
        {signUpError && (
          <Alert severity="error" style={{ marginBottom: 10 }}>
            {signUpError}
          </Alert>
        )}
        <Paper className="paper">
          <form onSubmit={this.handleSignUp} noValidate>
            <TextField
              label="Navn"
              variant="outlined"
              margin="normal"
              type="text"
              value={name}
              onChange={this.handleChangeName}
              disabled={isFormDisabled}
              name="name"
              autoComplete="name"
              autoFocus
              required
              fullWidth
            />
            <TextField
              label="Email"
              variant="outlined"
              margin="normal"
              type="email"
              autoComplete="email"
              value={email}
              onChange={this.handleChangeEmail}
              disabled={isFormDisabled}
              fullWidth
            />
            <TextField
              type={this.state.showPassword ? "text" : "password"}
              autoComplete="password"
              value={password}
              onChange={this.handleChangePassword}
              label="Kodeord"
              variant="outlined"
              margin="normal"
              disabled={isFormDisabled}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={this.handleClickShowPassword}
                      edge="end"
                    >
                      {this.state.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              label="By"
              variant="outlined"
              margin="normal"
              type="text"
              value={userLocation}
              onChange={this.handleChangeLocation}
              disabled={isFormDisabled}
              name="location"
              autoComplete="location"
              required
              fullWidth
            />
            <TextField
              select
              fullWidth
              variant="outlined"
              margin="normal"
              label="Notifikationer"
              value={isNotificationsAllowed}
              onChange={this.handleChangeIsNotificationsAllowed}
              disabled={isFormDisabled}
              SelectProps={{
                native: true
              }}
            >
              <option value={true}>Ja</option>
              <option value={false}>Nej</option>
            </TextField>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={isFormDisabled}
              style={{ marginTop: 10 }}
            >
              {isSigningUp ? "Opretter bruger..." : "Opret bruger"}
            </Button>
            <Button
              component={Link}
              to="/login"
              fullWidth
              variant="contained"
              color="secondary"
              style={{ marginTop: 10 }}
            >
              Har du allerede en bruger?
            </Button>
          </form>
        </Paper>
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    name,
    email,
    password,
    location: userLocation,
    isNotificationsAllowed,
    isAuthenticated,
    isSigningUp,
    signUpError
  } = state.users;
  return {
    name,
    email,
    password,
    userLocation,
    isNotificationsAllowed,
    isAuthenticated,
    isSigningUp,
    signUpError
  };
};

const mapDispatchToProps = dispatch => ({
  onChangeName: name => dispatch(onChangeName(name)),
  onChangeEmail: email => dispatch(onChangeEmail(email)),
  onChangePassword: password => dispatch(onChangePassword(password)),
  onChangeLocation: location => dispatch(onChangeLocation(location)),
  onChangeIsNotificationsAllowed: isNotificationsAllowed =>
    dispatch(onChangeIsNotificationsAllowed(isNotificationsAllowed)),
  signUp: (email, password) => dispatch(signUp(email, password))
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
