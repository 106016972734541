import React from "react";
import { connect } from "react-redux";
import { TextField, Button, Paper } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  onChangeName,
  onChangeLocation,
  onChangeIsNotificationsAllowed,
  updateUser
} from "../../redux/actions";

class Settings extends React.Component {
  handleChangeName = event => {
    this.props.onChangeName(event.target.value);
  };

  handleChangeLocation = event => {
    this.props.onChangeLocation(event.target.value);
  };

  handleChangeIsNotificationsAllowed = event => {
    this.props.onChangeIsNotificationsAllowed(event.target.value);
  };

  handleUpdateUser = event => {
    event.preventDefault();
    const {
      updateUser,
      name,
      userLocation,
      isNotificationsAllowed
    } = this.props;
    updateUser(name, userLocation, isNotificationsAllowed);
  };

  render() {
    const {
      isAuthenticated,
      name,
      userLocation,
      isNotificationsAllowed,
      isUpdatingUser,
      updateUserError,
      user
    } = this.props;
    const isFormDisabled = isUpdatingUser || !isAuthenticated;

    return (
      <>
        {updateUserError && <Alert severity="error">{updateUserError}</Alert>}
        <Paper className="paper">
          <form onSubmit={this.handleUpdateUser} noValidate>
            <TextField
              label="Email"
              variant="outlined"
              margin="normal"
              type="email"
              value={user.email}
              disabled
              fullWidth
            />

            <TextField
              label="Navn"
              variant="outlined"
              margin="normal"
              type="text"
              value={name}
              onChange={this.handleChangeName}
              disabled={isFormDisabled}
              name="name"
              autoComplete="name"
              fullWidth
            />

            <TextField
              label="By"
              variant="outlined"
              margin="normal"
              type="text"
              value={userLocation}
              onChange={this.handleChangeLocation}
              disabled={isFormDisabled}
              name="name"
              autoComplete="location"
              fullWidth
            />

            <TextField
              select
              label="Notifikationer"
              variant="outlined"
              margin="normal"
              value={isNotificationsAllowed}
              onChange={this.handleChangeIsNotificationsAllowed}
              disabled={isFormDisabled}
              name="notifications"
              fullWidth
              SelectProps={{
                native: true
              }}
            >
              <option value={true}>Ja</option>
              <option value={false}>Nej</option>
            </TextField>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={isFormDisabled}
              style={{ marginTop: 10 }}
            >
              {isUpdatingUser ? "Gemmer ændringer..." : "Gem ændringer"}
            </Button>
          </form>
        </Paper>
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    isAuthenticated,
    name,
    location: userLocation,
    isNotificationsAllowed,
    isUpdatingUser,
    updateUserError,
    user
  } = state.users;

  return {
    isAuthenticated,
    name,
    userLocation,
    isNotificationsAllowed,
    isUpdatingUser,
    updateUserError,
    user
  };
};

const mapDispatchToProps = dispatch => ({
  onChangeName: email => dispatch(onChangeName(email)),
  onChangeLocation: location => dispatch(onChangeLocation(location)),
  onChangeIsNotificationsAllowed: isNotificationsAllowed =>
    dispatch(onChangeIsNotificationsAllowed(isNotificationsAllowed)),
  updateUser: (name, isNotificationsAllowed) =>
    dispatch(updateUser(name, isNotificationsAllowed))
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
