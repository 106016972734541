import { push } from "react-router-redux";
import { myFirebase } from "../../firebase/firebase";

export const onChangeCity = city => ({
  type: "ON_CHANGE_CITY",
  payload: { city }
});

export const onChangeName = name => ({
  type: "ON_CHANGE_NAME",
  payload: { name }
});

export const onChangeLocation = location => ({
  type: "ON_CHANGE_LOCATION",
  payload: { location }
});

export const onChangeIsNotificationsAllowed = isNotificationsAllowed => ({
  type: "ON_CHANGE_IS_NOTIFICATIONS_ALLOWED",
  payload: { isNotificationsAllowed }
});

export const onChangeEmail = email => ({
  type: "ON_CHANGE_EMAIL",
  payload: { email }
});

export const onChangePassword = password => ({
  type: "ON_CHANGE_PASSWORD",
  payload: { password }
});

export const logout = () => async dispatch => {
  dispatch({ type: "LOGOUT_REQUEST" });
  try {
    await myFirebase.auth().signOut();
    dispatch({ type: "LOGOUT_SUCCESS" });
  } catch (error) {
    dispatch({ type: "LOGOUT_ERROR", error });
  }
};

export const forgotPassword = email => ({
  type: "FORGOT_PASSWORD",
  payload: { email }
});

export const signUp = (email, password, name) => async dispatch => {
  dispatch({ type: "SIGN_UP_REQUEST" });
  try {
    const response = await myFirebase
      .auth()
      .createUserWithEmailAndPassword(email, password);
    try {
      await myFirebase.auth().currentUser.updateProfile({ displayName: name });
    } catch (error) {
      console.log(error);
    }
    const { user } = response;
    dispatch({ type: "SIGN_UP_SUCCESS", user });
  } catch (error) {
    const { message } = error;
    dispatch({ type: "SIGN_UP_ERROR", error: message });
  }
};

export const login = (email, password) => async dispatch => {
  dispatch({ type: "LOGIN_REQUEST" });
  try {
    const response = await myFirebase
      .auth()
      .signInWithEmailAndPassword(email, password);
    const { user } = response;
    dispatch({ type: "LOGIN_SUCCESS", user });
  } catch (error) {
    const { message } = error;
    console.log(message);
    dispatch({ type: "LOGIN_ERROR", error: message });
  }
};

export const verifyAuth = () => dispatch => {
  dispatch({ type: "VERIFY_REQUEST" });
  myFirebase.auth().onAuthStateChanged(user => {
    if (user !== null) {
      dispatch({ type: "LOGIN_SUCCESS", user });
    }
    dispatch({ type: "VERIFY_SUCCESS" });
  });
};

export const updateUser = (name, isNotificationsAllowed) => async dispatch => {
  dispatch({ type: "UPDATE_USER_REQUEST" });
  console.log(222);
  try {
    await myFirebase.auth().currentUser.updateProfile({
      displayName: name,
      isNotificationsAllowed
    });
    dispatch({ type: "UPDATE_USER_SUCCESS" });
  } catch (error) {
    dispatch({ type: "UPDATE_USER_ERROR", error });
  }
};
