import React from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { CssBaseline, Container } from "@material-ui/core";
import Header from "./Layout/Header";
import TaskList from "./Tasks/TaskList";
import SignUp from "./Authentication/SignUp";
import Login from "./Authentication/Login";
import Settings from "./Authentication/Settings";
import ForgotPassword from "./Authentication/ForgotPassword";
import ProtectedRoute from "./Authentication/ProtectedRoute";
import "./App.css";

const App = ({ isAuthenticated, isVerifying }) => (
  <div className="App">
    <CssBaseline />
    <Header />
    <Container style={{ paddingTop: 30, paddingBlock: 30 }} maxWidth="sm">
      <Switch>
        <Route path="/sign-up" component={SignUp} />
        <Route path="/login" component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <ProtectedRoute
          path="/settings"
          component={Settings}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          path="/"
          component={TaskList}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
      </Switch>
    </Container>
  </div>
);

const mapStateToProps = state => {
  const { isAuthenticated, isVerifying } = state.users;
  return { isAuthenticated, isVerifying };
};

export default connect(mapStateToProps)(App);
